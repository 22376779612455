import $ from 'jquery';

function initializeLocationSelector() {
  $('.location_selector .locations .name').click(function() {
    $(this).closest('.location_selector').find('.locations .name').removeClass('active');
    $(this).addClass('active');

    $(this).closest('.form-group').find('input').val($(this).data('id'));
  });
}

$( document ).on('turbolinks:load', initializeLocationSelector);